import { FC, useEffect } from "react";

import { Authenticator, View } from '@aws-amplify/ui-react';
import { useLocation, useNavigate } from "react-router-dom";

import useAuth from "../hooks/auth.hook";

const authtenticatorFormFields = {
  signIn: {
    username: {
      placeholder: 'Email',
    },
  },
  signUp: {
    given_name: {
      placeholder: 'First Name',
      order: 1,
    },
    family_name: {
      placeholder: 'Last Name',
      order: 2,
    },
    username: {
      placeholder: 'Email',
      order: 3
    },
  },
  resetPassword: {
    username: {
      placeholder: 'Email',
    },
  },
};

const Login: FC = () => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const from = (location.state as any)?.from?.pathname || '/';

  useEffect(() => {
    if (isAuthenticated) {
      navigate(from, { replace: true });
    }
  }, [isAuthenticated, navigate, from]);

  return (
    <div 
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
      }}
    >
       <h1>Login</h1>
       <View
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
      <Authenticator 
        formFields={authtenticatorFormFields}
        signUpAttributes={['given_name', 'family_name']}
      />
    </View>
    </div>
   
  );
};

export default Login;
import { TRPCClientErrorLike } from '@trpc/client';
import { UseTRPCQueryResult } from '@trpc/react-query/shared';
import { useState } from 'react';

import { IStarter } from '@monorepo/core';
import type { AppRouter } from '@monorepo/lambda-trpc';

import usePresentToast from './present-toast.hook';
import { ReactQueryOptions, trpc } from '../utils/trpc';

export function useStarters(
  options?: ReactQueryOptions['starters']['list'],
): UseTRPCQueryResult<IStarter[], TRPCClientErrorLike<AppRouter>> {
  return trpc.starters.list.useQuery(undefined, options);
}

export function useAddStarter() {
  const utils = trpc.useUtils();
  const trpcMutation = trpc.starters.put.useMutation({
    onSuccess: (_starter) => {
      // Invalidate the list query on the starters router when a new starter is created
      utils.starters.list.invalidate();
    },
  });
  const { presentError } = usePresentToast();

  const [isAdding, setIsAdding] = useState(false);

  const addStarter = async (starter: Omit<IStarter, 'starterId'>) => {
    try {
      setIsAdding(true);
      return await trpcMutation.mutateAsync(starter);
    } catch (e) {
      presentError('Failed to put starter');
      throw e;
    } finally {
      setIsAdding(false);
    }
  };

  return {
    isAdding,
    addStarter,
  };
}

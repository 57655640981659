import { useAuthenticator } from "@aws-amplify/ui-react";
import { fetchAuthSession } from 'aws-amplify/auth';
import { createContext, FC, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
export interface AuthContextValue  {
  isAuthenticated: boolean;
  getIdToken: () => Promise<string | undefined>;
  logout: () => void;
}

const AuthContext = createContext<AuthContextValue>({
  isAuthenticated: false,
  getIdToken: () => Promise.resolve(''),
  logout: () => undefined,
});

interface ProviderProps {
  children: ReactNode;
}

export const AuthProvider: FC<ProviderProps> = ({ children }) => {
  const { user, signOut } = useAuthenticator((context: any) => [context.user]);
  const navigate = useNavigate();

  const getIdToken = async () => {
    // fetchAuthSession automatically refreshes the accessToken and idToken if the tokens are expired and valid refreshToken presented.
    const session= await fetchAuthSession();
    return session.tokens?.idToken?.toString();
  }

  const logout = () => {
    signOut();
    navigate('/');
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: !!user,
        getIdToken,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
